<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">

      <dark-Toggler class="d-lg-block"/>   

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user ml-1 ml-md-0"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ loggedCustomer.name }}
            </p>
            <span class="user-status">{{ loggedCustomer.email }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="loggedCustomer.image"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item 
          link-class="d-flex align-items-center"
          :to="{ name: 'account-settings'}"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Perfil</span>
        </b-dropdown-item>

        <b-dropdown-divider /> 

        <b-dropdown-item 
          link-class="d-flex align-items-center"
          @click.stop.prevent="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          
          <span>Sair</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import services from '@/services'
import store from '@/store'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BDropdownDivider
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { avatarText } from "@core/utils/filter"

export default {
    components: {
        BLink,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        BAvatar,
        DarkToggler,
        BDropdownDivider
    },

    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {},
        },
    },

    data() {
        return {
            avatarText
        }
    },

    methods: {
        async logout() {
            await services.auth.logout(this.$apolloProvider.defaultClient)
        }
    },

    computed: {
      loggedCustomer() {
          if (!store.state.customer.loggedCustomer.name) {
              return {
                  name: '',
                  email: ''
              }
          }
          else {
              return store.state.customer.loggedCustomer
          }
      },
    }

}
</script>
