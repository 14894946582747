<template>
	<ul>
		<component
			:is="resolveNavItemComponent(item)"
			v-for="item in computedItems"
			:key="item.header || item.title"
			:item="item"
		/>
	</ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import store from '@/store'

export default {
	components: {
		VerticalNavMenuHeader,
		VerticalNavMenuLink,
		VerticalNavMenuGroup,
	},
	props: {
		items: {
			type: Array,
			required: true,
		},
	},
	setup() {
		provide('openGroups', ref([]))

		return {
			resolveNavItemComponent,
		}
	},

	computed: {
		computedItems() {
			if (this.selectedContract.aquisicao_escritura === 'Sim') {
				return this.items.filter(item => item.title !== 'Financiamento')
			}
			return this.items
		},

		selectedContract() {
			return store.getters['contracts/getSelectedContract']
		},
	},
}
</script>
