<template>
  <layout-vertical>

    <router-view />

    <!-- Navbar -->
    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <!-- Menu Header -->
    <div slot="vertical-menu-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item mr-auto">
          <b-link
            class="navbar-brand"
            to="/"
          >
            <span class="brand-logo">
              <b-img
                :src="appLogoImage"
                alt="logo"
              />
            </span>
            <h2 class="brand-text">
                <span>Portal do</span><br />
                <span>Cliente</span>
            </h2>
          </b-link>
        </li>
      </ul>
    </div>

    <!-- Footer -->
    <div slot="footer">
      <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25">
          Copyright  © {{ new Date().getFullYear() }}
          <b-link
            class="ml-25"
            href="https://www.saobento.com.br"
            target="_blank"
          >São Bento Incorporadora</b-link>
        </span>
      </p>
    </div>        

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import { BLink, BImg } from 'bootstrap-vue'
import Navbar from '../components/Navbar.vue'
import { $themeConfig } from '@themeConfig'

const { appLogoImage } = $themeConfig.app

export default {
    components: {
        LayoutVertical,
        Navbar,
        BLink,
        BImg
    },

    data() {
        return {
            appLogoImage,      
        }
    },
}
</script>
